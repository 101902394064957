module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"jacks-heart.com","short_name":"jacks-heart.com","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"jacks-heart.com","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
